<template>
    <nav class="side-panel-nav nav flex-column bg-light-subtle border-start border-end" :class="{'side-panel-nav-left': left}">
        <button class="btn btn-sm btn-link side-panel-nav-button"
            :title="$t('Collapse')" @click="epxandSidePanel">
            <template v-if="!left">
                <i v-if="sizerState === 'collapsedLeft'" class="bi bi-chevron-right"></i>
                <i v-else class="bi bi-chevron-double-left"></i>
            </template>
            <template v-else>
                <i v-if="sizerState === 'collapsedRight'" class="bi bi-chevron-left"></i>
                <i v-else class="bi bi-chevron-double-right"></i>
            </template>
        </button>
        <button class="btn btn-sm btn-link side-panel-nav-button"
            :title="$t('Expand')" @click="expandComponent">
            <template v-if="left">
                <i v-if="sizerState === 'collapsedLeft'" class="bi bi-chevron-right"></i>
                <i v-else class="bi bi-chevron-double-left"></i>
            </template>
            <template v-else>
                <i v-if="sizerState === 'collapsedRight'" class="bi bi-chevron-left"></i>
                <i v-else class="bi bi-chevron-double-right"></i>
            </template>
        </button>

        <button v-if="hasDetails" class="btn btn-link btn-sm nav-link side-panel-nav-button"
            @click="toggleTab('details', skipCollapse)"
            :class="{ 'active': activeTab === 'details' }" :title="detailsTitle ?? $t('Details')">
                <div class="mb-1">
                    <i class="bi bi-card-text"></i>
                </div>
                <span class="side-panel-nav-text">{{ $t('Details') }}</span>
        </button>
        <button v-for="tab in tabs" class="btn btn-link btn-sm nav-link side-panel-nav-button"
            @click="toggleTab(tab.id, skipCollapse)"
            :class="{ 'active': activeTab === tab.id }" :title="tab.title">
                <div class="mb-1">
                    <i :class="tab.iconClass"></i>
                </div>
                <span class="side-panel-nav-text">{{ tab.title }}</span>
        </button>

        <button @click="switchSides()"
            class="btn btn-sm btn-link side-panel-nav-button mt-auto" 
            :title="$t(left ? 'Move side panel to the right side' : 'Move side panel to the left side')">
                <i v-if="left" class="bi bi-chevron-bar-right"></i>
                <i v-else class="bi bi-chevron-bar-left"></i>
        </button>
    </nav>
</template>

<script setup lang="ts">
import type { Ref, ComputedRef } from 'vue';

import { InjectionKeys } from 'o365-utils';
import { inject } from 'vue';

const props = defineProps<{
    left?: boolean
    skipCollapse?: boolean
}>();

const { sizerState, activeTab, hasDetails, detailsTitle, tabs, toggleTab, expandComponent, epxandSidePanel, switchSides} = inject<{
    sizerState: Ref<string>,
    activeTab: Ref<string>,
    hasDetails: ComputedRef<boolean>,
    detailsTitle: Ref<string>,
    toggleTab: (pId: string, pSkipCollapse?: boolean) => void,
    tabs: Ref<{ id: string, title: string, iconClass: string }[]>,
    epxandSidePanel: () => void,
    expandComponent: () => void,
    switchSides: () => void
}>(InjectionKeys.sidePaneComponentKey)!;

</script>

<style scoped>
.side-panel-nav {
    flex-wrap: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    margin-right: 2px;
    min-width: calc(2rem + 2px);
    max-width: calc(2rem + 2px);
}

.side-panel-nav-left {
    margin-left: 2px;
    margin-right: unset;
}

.side-panel-nav::-webkit-scrollbar {
    display: none;
}

.side-panel-nav-button.active {
    background-color: rgba(var(--bs-primary-rgb), .1);
    border-right: 2px solid rgba(var(--bs-primary-rgb), .5);
    color: rgb(var(--bs-primary-rgb));
    font-weight: 500;
}
.side-panel-nav-left .side-panel-nav-button.active {
    border-left: 2px solid rgba(var(--bs-primary-rgb), .5);
    border-right: unset;
}

.side-panel-nav-button {
    border-radius: 0px;
    padding-left: 0.25rem;
    padding-right: 0rem;
    width: 2rem;
}

.side-panel-nav-button:hover {
    background-color: rgba(var(--bs-primary-rgb), .15);
}

.side-panel-nav-text {
    writing-mode: vertical-lr;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>